import { PublicKey } from '@solana/web3.js';

export const DEFAULTS = {
  CLUSTER: 'mainnet',
  //todo these need to be PER cluster
  GEM_BANK_PROG_ID: new PublicKey(
    'HyU2scFpQh26Dqsz84sn8awUgug1VVVnEyyarRhAHoBS'
  ),
  GEM_FARM_PROG_ID: new PublicKey(
    '2YUrcqipw3Ao9ghr6rsCnmvVSDsh3kxJdmVC79QwQKAB'
  ),
};
