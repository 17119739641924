import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FixedScheduleDisplay = _resolveComponent("FixedScheduleDisplay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, "Estimated claimable: " + _toDisplayString(Math.floor((((_ctx.reward.accruedReward - _ctx.reward.paidOutReward) / (1000000000)) + (parseInt(_ctx.gems) * (Math.round(_ctx.currentTS/1000) - _ctx.reward.fixedRate.lastUpdatedTs) * 12 / 86400)) * 1000)/1000) + " $MOUNT", 1),
    _createVNode(_component_FixedScheduleDisplay, {
      key: _ctx.farmReward,
      gems: _ctx.gems,
      schedule: _ctx.reward.fixedRate.promisedSchedule
    }, null, 8, ["gems", "schedule"])
  ], 64))
}